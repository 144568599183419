import React from 'react';
import {
    Create,
    CreateProps,
    ImageField,
    ImageInput,
    NumberInput,
    ReferenceInput,
    required,
    SelectInput,
    SimpleForm,
    TextInput
} from 'react-admin';

export const CharityCreate = (props: CreateProps) => (
    <Create {...props}>
        <SimpleForm>
            <TextInput source="name" validate={[required()]} />
            <TextInput source="description" validate={[required()]} />
            <ImageInput source="logo_url" label="Charity Logo" accept="image/*">
                <ImageField source="src" title="logo" />
            </ImageInput>
            <ImageInput source="banner_url" label="Charity Banner" accept="image/*">
                <ImageField source="src" title="banner" />
            </ImageInput>
            <TextInput source="website" type="url" />
            <ReferenceInput
                label="Market"
                source="market_id"
                reference="market"
                validate={[required()]}
            >
                <SelectInput optionText="name" />
            </ReferenceInput>
            <NumberInput source="display_index" />
        </SimpleForm>
    </Create>
);
