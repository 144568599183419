import React from 'react';
import {
    ImageField,
    NumberField,
    ReferenceField,
    Show,
    ShowProps,
    SimpleShowLayout,
    TextField,
    UrlField
} from 'react-admin';
import LatTitle from '../common/LatTitle';

export const CharityShow = (props: ShowProps) => (
    <Show title={<LatTitle source="name" />} {...props}>
        <SimpleShowLayout>
            <TextField source="name" />
            <TextField source="description" />
            <ImageField source="logo_url" title="logo" />
            <ImageField source="banner_url" title="banner" />
            <UrlField source="website" />
            <ReferenceField label="Market" source="market_id" reference="market">
                <TextField source="name" />
            </ReferenceField>
            <NumberField source="display_index" />
        </SimpleShowLayout>
    </Show>
);
