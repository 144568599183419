import React from 'react';
import {
    BooleanInput,
    Create,
    CreateProps,
    NumberInput,
    required,
    SimpleForm,
    TextInput
} from 'react-admin';

export const MarketCreate = (props: CreateProps) => {
    return (
        <Create {...props}>
            <SimpleForm>
                <TextInput source="name" validate={[required()]} />
                <TextInput source="market_code" validate={[required()]} />
                <TextInput source="description" />
                <BooleanInput source="is_coming_soon" />
                <NumberInput
                    source="display_index"
                    validate={[required()]}
                    helperText="Lower is higher"
                />
            </SimpleForm>
        </Create>
    );
};
