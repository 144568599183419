import React from 'react';
import { Edit, EditProps, required, SimpleForm, TextInput } from 'react-admin';

export const BlacklistEdit = (props: EditProps) => (
    <Edit {...props}>
        <SimpleForm>
            <TextInput source="name" validate={[required()]} />
        </SimpleForm>
    </Edit>
);
