import { useMediaQuery } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import React from 'react';
import {
    Datagrid,
    DateField,
    List,
    ListProps,
    ReferenceField,
    SimpleList,
    TextField
} from 'react-admin';

export const CashbackList = (props: ListProps) => {
    const theme = useTheme();
    const isSmall = useMediaQuery(theme.breakpoints.down('sm'));
    return (
        <List {...props}>
            {isSmall ? (
                <SimpleList
                    linkType="show"
                    primaryText={(record) => record.vendor_id}
                    secondaryText={(record) => record.user_id}
                    tertiaryText={(record) => record.transaction_us_cents}
                />
            ) : (
                <Datagrid>
                    <ReferenceField label="Vendor" source="vendor_id" reference="vendor">
                        <TextField source="name" />
                    </ReferenceField>
                    <ReferenceField label="User" source="user_id" reference="user">
                        <TextField source="email" />
                    </ReferenceField>
                    <TextField source="transaction_uid" />
                    <TextField source="cashback_us_cents" />
                    <TextField source="transaction_us_cents" />
                    <TextField source="adjustment_uid" />
                    <DateField source="date_created" />
                    <DateField source="date_updated" />
                </Datagrid>
            )}
        </List>
    );
};
