import { useMediaQuery } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import * as React from 'react';
import {
    Datagrid,
    DateField,
    ImageField,
    List,
    ListProps,
    NumberField,
    ReferenceField,
    SimpleList,
    TextField
} from 'react-admin';

export const CharityList = (props: ListProps) => {
    const theme = useTheme();
    const isSmall = useMediaQuery(theme.breakpoints.down('sm'));
    return (
        <List {...props}>
            {isSmall ? (
                <SimpleList
                    linkType="show"
                    primaryText={(record) => record.name}
                    secondaryText={(record) => record.description}
                    tertiaryText={(record) => new Date(record.date_created).toLocaleDateString()}
                />
            ) : (
                <Datagrid rowClick="show">
                    <ImageField source="logo_url" title="logo" />
                    <TextField source="name" />
                    <TextField source="description" />
                    <ReferenceField label="Market" source="market_id" reference="market">
                        <TextField source="name" />
                    </ReferenceField>
                    <NumberField source="display_index" />
                    <DateField source="date_created" />
                    <DateField source="date_updated" />
                </Datagrid>
            )}
        </List>
    );
};
