import { useMediaQuery } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import * as React from 'react';
import { Datagrid, DateField, List, ListProps, SimpleList, TextField } from 'react-admin';

export const BlacklistList = (props: ListProps) => {
    const theme = useTheme();
    const isSmall = useMediaQuery(theme.breakpoints.down('sm'));
    return (
        <List {...props}>
            {isSmall ? (
                <SimpleList
                    linkType="show"
                    primaryText={(record) => record.name}
                    secondaryText={(record) => new Date(record.date_created).toLocaleDateString()}
                />
            ) : (
                <Datagrid rowClick="show">
                    <TextField source="name" />
                    <DateField source="date_created" />
                    <DateField source="date_updated" />
                </Datagrid>
            )}
        </List>
    );
};
