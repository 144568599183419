import { useAuth0, withAuthenticationRequired } from '@auth0/auth0-react';
import LocalMallIcon from '@material-ui/icons/LocalMall';
import MailIcon from '@material-ui/icons/Mail';
import PlaceIcon from '@material-ui/icons/Place';
import CategoryIcon from '@mui/icons-material/Category';
import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import PlaylistRemoveIcon from '@mui/icons-material/PlaylistRemove';
import SummarizeIcon from '@mui/icons-material/Summarize';
import ViewListIcon from '@mui/icons-material/ViewList';
import VolunteerActivismIcon from '@mui/icons-material/VolunteerActivism';
import { Admin, Loading, Resource } from 'react-admin';
import {
    BlacklistCreate,
    BlacklistEdit,
    BlacklistList,
    BlacklistShow
} from './components/blacklist';
import { CashbackList } from './components/cashback';
import { CategoryCodesEdit, CategoryCodesList } from './components/categoryCodes';
import { CharityDonationList } from './components/charityDonations';
import { CharityCreate, CharityEdit, CharityList, CharityShow } from './components/charitys';
import { InvitationList, InvitationShow } from './components/invitations';
import { MarketCreate, MarketEdit, MarketList, MarketShow } from './components/market';
import { PlansEdit, PlansShow } from './components/plans';
import { PlansList } from './components/plans/List';
import {
    PromoCodeCreate,
    PromoCodeEdit,
    PromoCodeList,
    PromoCodeShow
} from './components/promoCodes';
import { TransactionsEdit, TransactionsList, TransactionsShow } from './components/transactions';
import { VendorCreate, VendorEdit, VendorList, VendorShow } from './components/vendors';
import { getAuthProvider } from './utils/authProvider';
import { getDataProvider } from './utils/dataProvider';
import theme from './utils/theme';

const App = () => {
    const auth0 = useAuth0();
    const authProvider = getAuthProvider(auth0);
    const dataProvider = getDataProvider(auth0);
    return (
        <Admin dataProvider={dataProvider} authProvider={authProvider} theme={theme}>
            <Resource
                name="market"
                list={MarketList}
                create={MarketCreate}
                edit={MarketEdit}
                show={MarketShow}
                icon={PlaceIcon}
            />
            <Resource
                name="category_code"
                list={CategoryCodesList}
                edit={CategoryCodesEdit}
                icon={CategoryIcon}
            />
            <Resource
                name="transaction"
                list={TransactionsList}
                show={TransactionsShow}
                edit={TransactionsEdit}
                icon={CurrencyExchangeIcon}
            />
            <Resource name="cashback" list={CashbackList} icon={SummarizeIcon} />
            <Resource
                name="charity"
                list={CharityList}
                show={CharityShow}
                create={CharityCreate}
                edit={CharityEdit}
                icon={VolunteerActivismIcon}
            />
            <Resource name="charity_donation" list={CharityDonationList} icon={SummarizeIcon} />
            <Resource
                name="plan"
                list={PlansList}
                show={PlansShow}
                edit={PlansEdit}
                icon={ViewListIcon}
            />
            <Resource
                name="vendor"
                list={VendorList}
                show={VendorShow}
                create={VendorCreate}
                edit={VendorEdit}
                icon={LocalMallIcon}
            />
            <Resource
                name="blacklist"
                list={BlacklistList}
                show={BlacklistShow}
                create={BlacklistCreate}
                edit={BlacklistEdit}
                icon={PlaylistRemoveIcon}
            />
            <Resource
                name="promo_code"
                list={PromoCodeList}
                show={PromoCodeShow}
                edit={PromoCodeEdit}
                create={PromoCodeCreate}
                icon={LocalOfferIcon}
            />
            <Resource
                name="invitation"
                list={InvitationList}
                show={InvitationShow}
                icon={MailIcon}
            />
            <Resource name="user" />
        </Admin>
    );
};

export default withAuthenticationRequired(App, {
    onRedirecting: () => (
        <Loading
            loadingPrimary="Elevate your finances"
            loadingSecondary="Seek to be worth knowing"
        />
    )
});
