import { useMediaQuery } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import React from 'react';
import {
    Datagrid,
    DateField,
    Filter,
    FunctionField,
    List,
    ListProps,
    ReferenceField,
    SimpleList,
    TextField,
    TextInput
} from 'react-admin';
import { formatPrice } from '../../utils/format';
import TransactionsEmpty from './TransactionsEmpty';

const rizeUrl = process.env.REACT_APP_RIZE_BASE_URL ?? 'https://admin-sandbox.rizefs.com/';

const TransactionFilter = (props: any) => (
    <Filter {...props}>
        <TextInput label="Search" source="q" alwaysOn />
    </Filter>
);

export const TransactionsList = (props: ListProps) => {
    const theme = useTheme();
    const isSmall = useMediaQuery(theme.breakpoints.down('sm'));
    // const [offset, setOffset] = useState('0');

    // const TransactionsListActions = () => (
    //     <TopToolbar>
    //         <Input
    //             name="offset"
    //             placeholder="Offset"
    //             value={offset}
    //             style={{ color: 'white' }}
    //             onChange={(e) => setOffset(e.target.value)}
    //         />
    //         <SyncButton offset={Number(offset)} />
    //     </TopToolbar>
    // );

    return (
        <List
            {...props}
            empty={<TransactionsEmpty />}
            // actions={<TransactionsListActions />}
            filters={<TransactionFilter />}
        >
            {isSmall ? (
                <SimpleList
                    linkType="show"
                    primaryText={(record) => `${record.name} (${record.market_code})`}
                    secondaryText={(record) => record.description}
                    tertiaryText={(record) => (record.is_coming_soon ? 'Coming Soon' : 'Active')}
                />
            ) : (
                <Datagrid rowClick="show">
                    <FunctionField
                        label="Rize"
                        render={(trx: any) => (
                            <>
                                <a
                                    target="_blank"
                                    rel="noreferrer"
                                    href={`${rizeUrl}customer/${trx.source_customer_id}/transactions/${trx.rize_uid}`}
                                >
                                    Transaction
                                </a>
                                <br />
                                <a
                                    target="_blank"
                                    rel="noreferrer"
                                    href={`${rizeUrl}customer/${trx.source_customer_id}`}
                                >
                                    Source&nbsp;Customer
                                </a>
                                <br />
                                <a
                                    target="_blank"
                                    rel="noreferrer"
                                    href={`${rizeUrl}customer/${trx.destination_customer_id}`}
                                >
                                    Destination&nbsp;Customer
                                </a>
                            </>
                        )}
                    />
                    <TextField label="Rize Type" source="type" />
                    <TextField label="Latitude Type" source="transaction_type" />
                    <TextField label="Point Count" source="point_count" />
                    <TextField source="merchant_name" />
                    <ReferenceField label="LBN Vendor" source="vendor_id" reference="vendor">
                        <TextField source="name" />
                    </ReferenceField>
                    <FunctionField
                        label="Amount"
                        render={(trx: any) =>
                            `${trx.net_asset === 'negative' ? '-' : ''}${formatPrice(
                                trx.us_dollar_amount
                            )}`
                        }
                    />
                    <DateField showTime source="date_created" />
                </Datagrid>
            )}
        </List>
    );
};
