import { useMediaQuery } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import React from 'react';
import {
    Datagrid,
    DateField,
    List,
    ListProps,
    ReferenceField,
    SimpleList,
    TextField
} from 'react-admin';

export const CharityDonationList = (props: ListProps) => {
    const theme = useTheme();
    const isSmall = useMediaQuery(theme.breakpoints.down('sm'));
    return (
        <List {...props}>
            {isSmall ? (
                <SimpleList
                    linkType="show"
                    primaryText={(record) => record.donation_us_cents}
                    secondaryText={(record) => record.transaction_type}
                    tertiaryText={(record) => record.transaction_uid}
                />
            ) : (
                <Datagrid rowClick="show">
                    <ReferenceField label="User" source="user_id" reference="user">
                        <TextField source="email" />
                    </ReferenceField>
                    <TextField source="transaction_uid" />
                    <TextField source="donation_us_cents" />
                    <TextField source="transaction_us_cents" />
                    <TextField source="donation_percent" />
                    <TextField source="transaction_type" />
                    <TextField source="local_donation_us_cents" />
                    <TextField source="preferred_donation_us_cents" />
                    <TextField source="other_donation_us_cents" />
                    <TextField source="fallback_donation_percent" />
                    <DateField source="date_created" />
                    <DateField source="date_updated" />
                </Datagrid>
            )}
        </List>
    );
};
