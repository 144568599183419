import React from 'react';
import { Create, CreateProps, required, SimpleForm, TextInput } from 'react-admin';

export const BlacklistCreate = (props: CreateProps) => (
    <Create {...props}>
        <SimpleForm>
            <TextInput source="name" validate={[required()]} />
        </SimpleForm>
    </Create>
);
