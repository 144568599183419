import { useMediaQuery } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import {
    BooleanField,
    Datagrid,
    DateField,
    List,
    ListProps,
    NumberField,
    SimpleList,
    TextField
} from 'react-admin';

export const PromoCodeList = (props: ListProps) => {
    const theme = useTheme();
    const isSmall = useMediaQuery(theme.breakpoints.down('sm'));
    return (
        <List {...props}>
            {isSmall ? (
                <SimpleList
                    primaryText={(record) => record.code}
                    secondaryText={(record) => (record.active ? 'Active' : 'Deactivated')}
                    tertiaryText={(record) => new Date(record.date_created).toLocaleDateString()}
                />
            ) : (
                <Datagrid rowClick="show">
                    <TextField source="code" />
                    <NumberField source="discounted_cents" />
                    <BooleanField source="active" />
                    <DateField source="date_created" />
                </Datagrid>
            )}
        </List>
    );
};
