import React from 'react';
import {
    ArrayField,
    ChipField,
    ImageField,
    NumberField,
    ReferenceField,
    Show,
    ShowProps,
    SimpleShowLayout,
    SingleFieldList,
    TextField,
    UrlField
} from 'react-admin';
import LatTitle from '../common/LatTitle';

export const VendorShow = (props: ShowProps) => (
    <Show title={<LatTitle source="name" />} {...props}>
        <SimpleShowLayout>
            <TextField source="name" />
            <ArrayField source="transaction_names" label="Merchant Transaction Names">
                <SingleFieldList>
                    <ChipField source="merchant_name" clickable={false} />
                </SingleFieldList>
            </ArrayField>
            <TextField source="tagline" />
            <ArrayField source="merchant_numbers">
                <SingleFieldList>
                    <ChipField source="number" clickable={false} />
                </SingleFieldList>
            </ArrayField>
            <TextField source="description" />
            <ImageField source="image" title="Merchant Image" />
            <UrlField source="website" />
            <TextField source="promotion" />
            <TextField source="full_address" />
            <NumberField source="latitude" />
            <NumberField source="longitude" />
            <ReferenceField label="Market" source="market_id" reference="market">
                <TextField source="name" />
            </ReferenceField>
        </SimpleShowLayout>
    </Show>
);
