import React from 'react';
import {
    BooleanField,
    DateField,
    NumberField,
    Show,
    ShowProps,
    SimpleShowLayout,
    TextField
} from 'react-admin';
import LatTitle from '../common/LatTitle';

export const PlansShow = (props: ShowProps) => (
    <Show title={<LatTitle source="name" />} {...props}>
        <SimpleShowLayout>
            <TextField source="name" />
            <TextField source="description" />
            <TextField source="priceCopy" />
            <NumberField source="price" options={{ style: 'currency', currency: 'USD' }} />
            <BooleanField source="is_coming_soon" />
            <NumberField source="membership_bonus_points" />
            <NumberField source="initial_invites" />
            <NumberField source="meals_donated" />
            <NumberField source="lbn_cashback_percent" options={{ style: 'percent' }} />
            <NumberField source="lbn_donation_percent" options={{ style: 'percent' }} />
            <NumberField source="local_donation_percent" options={{ style: 'percent' }} />
            <NumberField source="other_donation_percent" options={{ style: 'percent' }} />
            <NumberField source="high_yield_target_apy" options={{ style: 'percent' }} />
            <DateField source="date_created" />
            <DateField source="date_updated" />
        </SimpleShowLayout>
    </Show>
);
