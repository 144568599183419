import { Auth0ContextInterface, User } from '@auth0/auth0-react';
import { stringify } from 'query-string';
import { CreateParams, DataProvider, fetchUtils, UpdateParams } from 'react-admin';
import { uploadImageRequest } from './api';
import dataConfig from './dataConfig';

const apiUrl = dataConfig.apiBaseUrl;

const ADMIN_RESOURCES = [
    'cashback',
    'category_code',
    'charity_donation',
    'promo_code',
    'transaction',
    'user',
    'vendor'
];

const mapResourceToUrl = (resource: string) => {
    // Add admin prefix to admin routes
    if (ADMIN_RESOURCES.includes(resource)) {
        return `admin/${resource}`;
    } else {
        return resource;
    }
};

/** Category Code id doesn't follow the naming scheme */
const resourceId = (resource: string) =>
    resource === 'category_code' ? 'category_id' : `${resource}_id`;

export const getDataProvider = (auth0: Auth0ContextInterface<User>) => {
    const getClient = async (url: string, data: any = {}) => {
        const accessToken = await auth0.getAccessTokenSilently();
        const options = {
            user: {
                authenticated: true,
                token: `Bearer ${accessToken}`
            },
            ...data
        };
        return fetchUtils.fetchJson(url, options);
    };

    const mapRecordId = (json: { data: any[] }, resource: string) => {
        const id = resourceId(resource);
        return json.data.map((r: any) => ({ ...r, id: r[id] }));
    };

    const defaultUpdate = (resource: string, params: UpdateParams<any>) => {
        const resourceUrl = mapResourceToUrl(resource);
        return getClient(`${apiUrl}/${resourceUrl}/${params.id}`, {
            method: 'PUT',
            body: JSON.stringify(params.data)
        }).then(({ json }) => ({
            data: { ...json.data, id: json.data[resourceId(resource)] }
        }));
    };

    const defaultCreate = (resource: string, params: CreateParams<any>) => {
        const resourceUrl = mapResourceToUrl(resource);
        return getClient(`${apiUrl}/${resourceUrl}`, {
            method: 'POST',
            body: JSON.stringify(params.data)
        }).then(({ json }) => ({
            data: { ...json.data, id: json.data[resourceId(resource)] }
        }));
    };

    const isCharityTransformer = (resource: string, params: any) => {
        // If resource name is charity and logo or banner are being updated
        return resource === 'charity' && (!!params.data.logo_url || !!params.data.banner_url);
    };

    const charityUpdateTransformer = async (data: any) => {
        /**
         * For charity update only, upload logo and banner to s3 and then change to url
         */
        const accessToken = await auth0.getAccessTokenSilently();
        let newData = data;

        if (newData.logo_url && newData.logo_url.rawFile instanceof File) {
            const response = await uploadImageRequest(
                accessToken,
                newData.logo_url.rawFile,
                'charity/logo'
            );
            const data = await response.json();
            newData.logo_url = data.url;
        }

        if (newData.banner_url && newData.banner_url.rawFile instanceof File) {
            const response = await uploadImageRequest(
                accessToken,
                newData.banner_url.rawFile,
                'charity/banner'
            );
            const data = await response.json();
            newData.banner_url = data.url;
        }

        return newData;
    };

    const isVendorTransformer = (resource: string, params: any) => {
        // If resource name is vendor and logo or banner are being updated
        return resource === 'vendor' && !!params.data.image;
    };

    const vendorUpdateTransformer = async (data: any) => {
        /**
         * For vendor update only, upload image to s3 and then change to url
         */
        const accessToken = await auth0.getAccessTokenSilently();
        let newData = data;

        if (newData.image && newData.image.rawFile instanceof File) {
            const response = await uploadImageRequest(
                accessToken,
                newData.image.rawFile,
                'vendor/image'
            );
            const data = await response.json();
            newData.image = data.url;
        }

        return newData;
    };

    return {
        getList: async (resource, params) => {
            const { page, perPage } = params.pagination;
            let { field, order } = params.sort;
            if (field === 'id') {
                field = resourceId(resource);
            }
            const query = {
                sort: JSON.stringify([field, order]),
                limit: perPage,
                offset: (page - 1) * perPage,
                filter: JSON.stringify(params.filter)
            };
            const resourceUrl = mapResourceToUrl(resource);
            const url = `${apiUrl}/${resourceUrl}/?${stringify(query)}`;
            let { json } = await getClient(url);

            return {
                data: mapRecordId(json, resource),
                total: json.total
            };
        },

        getOne: (resource, params) => {
            const resourceUrl = mapResourceToUrl(resource);
            return getClient(`${apiUrl}/${resourceUrl}/${params.id}`).then(({ json }) => ({
                data: { ...json.data, id: json.data[resourceId(resource)] }
            }));
        },

        getMany: async (resource, params) => {
            const query = {
                filter: JSON.stringify({ id: params.ids })
            };
            const resourceUrl = mapResourceToUrl(resource);
            const url = `${apiUrl}/${resourceUrl}/?${stringify(query)}`;
            const { json } = await getClient(url);

            return { data: mapRecordId(json, resource) };
        },

        getManyReference: async (resource, params) => {
            const { page, perPage } = params.pagination;
            const { field, order } = params.sort;
            const query = {
                sort: JSON.stringify([field, order]),
                range: JSON.stringify([(page - 1) * perPage, page * perPage - 1]),
                filter: JSON.stringify({
                    ...params.filter,
                    [params.target]: params.id
                })
            };
            const resourceUrl = mapResourceToUrl(resource);
            const url = `${apiUrl}/${resourceUrl}?${stringify(query)}`;
            const { json } = await getClient(url);

            return {
                data: json,
                total: 10
            };
        },

        update: async (resource, params) => {
            let data = params.data;
            if (isCharityTransformer(resource, params)) {
                data = await charityUpdateTransformer(params.data);
            }
            if (isVendorTransformer(resource, params)) {
                data = await vendorUpdateTransformer(params.data);
            }

            return defaultUpdate(resource, {
                ...params,
                data
            });
        },

        updateMany: async (resource, params) => {
            const query = {
                filter: JSON.stringify({ id: params.ids })
            };
            const resourceUrl = mapResourceToUrl(resource);
            const { json } = await getClient(`${apiUrl}/${resourceUrl}?${stringify(query)}`, {
                method: 'PUT',
                body: JSON.stringify(params.data)
            });
            return { data: json };
        },

        create: async (resource, params) => {
            let data = params.data;
            if (isCharityTransformer(resource, params)) {
                data = await charityUpdateTransformer(params.data);
            }
            if (isVendorTransformer(resource, params)) {
                data = await vendorUpdateTransformer(params.data);
            }

            return defaultCreate(resource, {
                ...params,
                data
            });
        },

        delete: (resource, params) => {
            const resourceUrl = mapResourceToUrl(resource);
            return getClient(`${apiUrl}/${resourceUrl}/${params.id}`, {
                method: 'DELETE'
            }).then(({ json }) => ({ data: json }));
        },

        deleteMany: (resource, params) => {
            const resourceUrl = mapResourceToUrl(resource);
            return Promise.all(
                params.ids.map((id) =>
                    getClient(`${apiUrl}/${resourceUrl}/${id}`, {
                        method: 'DELETE'
                    })
                )
            ).then((responses) => ({ data: responses.map(({ json }) => json.id) }));
        }
    } as DataProvider;
};
