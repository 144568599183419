import React from 'react';
import {
    BooleanInput,
    Edit,
    EditProps,
    NumberInput,
    required,
    SimpleForm,
    TextInput
} from 'react-admin';

export const PlansEdit = (props: EditProps) => (
    <Edit {...props}>
        <SimpleForm>
            <TextInput source="name" validate={[required()]} />
            <TextInput source="description" multiline validate={[required()]} />
            <TextInput source="priceCopy" validate={[required()]} />
            <NumberInput source="price" validate={[required()]} />
            <BooleanInput source="is_coming_soon" validate={[required()]} />
            <NumberInput source="membership_bonus_points" validate={[required()]} />
            <NumberInput source="initial_invites" validate={[required()]} />
            <NumberInput source="meals_donated" validate={[required()]} />
            <NumberInput source="lbn_cashback_percent" validate={[required()]} />
            <NumberInput source="lbn_donation_percent" validate={[required()]} />
            <NumberInput source="local_donation_percent" validate={[required()]} />
            <NumberInput source="other_donation_percent" validate={[required()]} />
            <NumberInput source="high_yield_target_apy" validate={[required()]} />
        </SimpleForm>
    </Edit>
);
