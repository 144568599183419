import React from 'react';
import { useTheme } from '@material-ui/core/styles';
import { useMediaQuery } from '@material-ui/core';
import {
    List,
    Datagrid,
    TextField,
    ListProps,
    SimpleList,
    BooleanField,
    NumberField
} from 'react-admin';

export const MarketList = (props: ListProps) => {
    const theme = useTheme();
    const isSmall = useMediaQuery(theme.breakpoints.down('sm'));
    return (
        <List {...props}>
            {isSmall ? (
                <SimpleList
                    linkType="show"
                    primaryText={(record) => `${record.name} (${record.market_code})`}
                    secondaryText={(record) => record.description}
                    tertiaryText={(record) => (record.is_coming_soon ? 'Coming Soon' : 'Active')}
                />
            ) : (
                <Datagrid rowClick="show">
                    <TextField source="name" />
                    <TextField source="market_code" />
                    <TextField source="description" />
                    <BooleanField source="is_coming_soon" />
                    <NumberField source="display_index" />
                </Datagrid>
            )}
        </List>
    );
};
