import React from 'react';
import {
    ArrayInput,
    Create,
    CreateProps,
    ImageField,
    ImageInput,
    NumberInput,
    ReferenceInput,
    required,
    SelectInput,
    SimpleForm,
    SimpleFormIterator,
    TextInput
} from 'react-admin';

export const VendorCreate = (props: CreateProps) => (
    <Create {...props}>
        <SimpleForm>
            <TextInput source="name" validate={[required()]} />
            <TextInput source="tagline" />
            <ArrayInput source="merchant_numbers">
                <SimpleFormIterator>
                    <NumberInput label="Merchant Identification Number" source="number" />
                </SimpleFormIterator>
            </ArrayInput>
            <ArrayInput source="transaction_names" label="Merchant Transaction Names">
                <SimpleFormIterator>
                    <TextInput source="merchant_name" label="Merchant Transaction Name" fullWidth />
                </SimpleFormIterator>
            </ArrayInput>
            <TextInput source="description" multiline />
            <TextInput source="website" type="url" />
            <ImageInput source="image" label="Merchant Image" accept="image/*">
                <ImageField source="src" title="banner" />
            </ImageInput>
            <TextInput source="promotion" />
            <TextInput source="full_address" />
            <NumberInput source="latitude" />
            <NumberInput source="longitude" />
            <ReferenceInput
                label="Market"
                source="market_id"
                reference="market"
                validate={[required()]}
            >
                <SelectInput optionText="name" />
            </ReferenceInput>
        </SimpleForm>
    </Create>
);
