import React from 'react';
import { DateField, Show, ShowProps, SimpleShowLayout, TextField } from 'react-admin';
import LatTitle from '../common/LatTitle';

export const BlacklistShow = (props: ShowProps) => (
    <Show title={<LatTitle source="name" />} {...props}>
        <SimpleShowLayout>
            <TextField source="name" />
            <DateField source="date_created" />
            <DateField source="date_updated" />
        </SimpleShowLayout>
    </Show>
);
