import { createMuiTheme } from '@material-ui/core/styles';

const myTheme = createMuiTheme({
    palette: {
        type: 'dark',
        primary: {
            main: '#009e93'
        },
        secondary: {
            main: '#b38b7d'
        },
        error: {
            main: '#e67373'
        },
        background: {
            default: '#2d2a2e',
            paper: '#242225'
        },
        text: {
            primary: '#d3d3d3'
        },
        divider: '#363636',
        contrastThreshold: 3,
        tonalOffset: 0.2
    },
    typography: {
        // Use the system font instead of the default Roboto font.
        fontFamily: [
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Arial',
            'sans-serif'
        ].join(',')
    },
    overrides: {
        MuiButton: {
            // override the styles of all instances of this component
            root: {
                // Name of the rule
                color: 'white' // Some CSS
            }
        }
    }
});

export default myTheme;
