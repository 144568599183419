import { Auth0ContextInterface, User } from '@auth0/auth0-react';
import { AuthProvider } from 'ra-core';

import authConfig from './authConfig';

export const getAuthProvider = (auth0: Auth0ContextInterface<User>) => {
    return {
        // called when the user attempts to log in
        login: (url) => {
            if (typeof url === 'undefined') {
                return auth0.loginWithRedirect({
                    audience: authConfig.audience,
                    scope: authConfig.scope
                });
            }
            return auth0.handleRedirectCallback(url.location);
        },
        // called when the user clicks on the logout button
        logout: () => {
            if (auth0.isAuthenticated) {
                // need to check for this as react-admin calls logout in case checkAuth failed
                return auth0.logout({
                    returnTo: window.location.origin
                });
            }
            return Promise.resolve();
        },
        // called when the API returns an error
        checkError: ({ status }) => {
            if (status === 401 || status === 403) {
                return Promise.reject();
            }
            return Promise.resolve();
        },
        // called when the user navigates to a new location, to check for authentication
        checkAuth: () => {
            if (auth0.isAuthenticated) {
                return Promise.resolve();
            }
            return auth0.getAccessTokenSilently({
                audience: authConfig.audience,
                scope: authConfig.scope
            });
        },
        // called when the user navigates to a new location, to check for permissions / roles
        getPermissions: () => {
            return Promise.resolve();
        }
    } as AuthProvider;
};
