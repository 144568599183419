import React from 'react';
import {
    BooleanField,
    DateField,
    NumberField,
    Show,
    ShowProps,
    Tab,
    TabbedShowLayout,
    TextField
} from 'react-admin';
import LatTitle from '../common/LatTitle';

export const TransactionsShow = (props: ShowProps) => (
    <Show title={<LatTitle source="name" />} {...props}>
        <TabbedShowLayout>
            <Tab label="summary">
                <NumberField source="transaction_id" />
                <NumberField source="source_account_id" />
                <NumberField source="destination_account_id" />
                <TextField source="external_uid" />
                <TextField source="rize_uid" />
                <TextField source="mx_uid" />
                <TextField source="debit_card_uid" />
                <TextField source="description" />
                <TextField source="mcc" />
                <TextField source="merchant_location" />
                <TextField source="merchant_source" />
                <TextField source="merchant_number" />
                <TextField source="net_asset" />
                <TextField source="status" />
                <TextField source="type" />
                <TextField source="us_dollar_amount" />
                <DateField showTime source="settled_at" />
                <NumberField source="settled_index" />
                <DateField showTime source="date_created" />
                <DateField showTime source="date_updated" />
            </Tab>
            <Tab label="mx_fields">
                <TextField source="mx_data.guid" />
                <TextField source="mx_data.memo" />
                <TextField source="mx_data.status" />
                <TextField source="mx_data.user_id" />
                <TextField source="mx_data.category" />
                <NumberField source="mx_data.latitude" />
                <TextField source="mx_data.metadata" />
                <NumberField source="mx_data.longitude" />
                <TextField source="mx_data.member_id" />
                <DateField showTime source="mx_data.posted_at" />
                <DateField source="mx_data.posted_on" />
                <TextField source="mx_data.user_guid" />
                <TextField source="mx_data.account_id" />
                <TextField source="mx_data.check_image" />
                <TextField source="mx_data.description" />
                <TextField source="mx_data.member_guid" />
                <TextField source="mx_data.account_guid" />
                <TextField source="mx_data.check_number" />
                <TextField source="mx_data.category_guid" />
                <TextField source="mx_data.currency_code" />
                <TextField source="mx_data.merchant_guid" />
                <DateField showTime source="mx_data.transacted_at" />
                <DateField source="mx_data.transacted_on" />
                <TextField source="mx_data.localized_memo" />
                <BooleanField source="mx_data.is_international" />
                <TextField source="mx_data.localized_description" />
                <TextField source="mx_data.merchant_category_code" />
                <TextField source="mx_data.merchant_location_guid" />
                <TextField source="mx_data.extended_transaction_type" />
            </Tab>
        </TabbedShowLayout>
    </Show>
);
