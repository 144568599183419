import { Typography } from '@mui/material';
import { Box } from 'rebass';
import SyncButton from './SyncTransactionsButton';

const Empty = () => {
    return (
        <Box textAlign="center" m={1}>
            <Typography variant="h4" paragraph>
                No transactions
            </Typography>
            <Typography variant="body1">Trigger transaction sync with rize?</Typography>
            <SyncButton />
        </Box>
    );
};

export default Empty;
