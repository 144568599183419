import { useAuth0 } from '@auth0/auth0-react';
import React, { useEffect } from 'react';
import { Button, Flex, Heading, Text } from 'rebass';
import Admin from './Admin';
import authConfig from './utils/authConfig';

const App = () => {
    const auth0 = useAuth0();

    useEffect(() => {
        auth0
            .getAccessTokenSilently({
                audience: authConfig.audience,
                scope: authConfig.scope
            })
            .catch((err) => {
                console.error(err);
            });
    }, [auth0]);

    if (auth0.isAuthenticated || auth0.isLoading) {
        return <Admin />;
    }
    if (auth0.error) {
        return (
            <Flex
                backgroundColor="#2d2a2e"
                flexDirection="column"
                height="100vh"
                width="100vw"
                justifyContent="center"
                alignItems="center"
            >
                <Text color="#e67373" mb={2}>
                    {auth0.error?.message}
                </Text>
                <Button
                    mt={4}
                    backgroundColor="#009e93"
                    onClick={() => {
                        auth0.logout();
                    }}
                >
                    Logout
                </Button>
            </Flex>
        );
    }
    return (
        <Flex
            backgroundColor="#2d2a2e"
            flexDirection="column"
            height="100vh"
            width="100vw"
            justifyContent="center"
            alignItems="center"
        >
            <Heading color="#d3d3d3" mb={2}>
                Latitude
            </Heading>
            <Text color="#d3d3d3" mb={2}>
                Elevate Your Finances, Lifestyle & Community
            </Text>
            <Button
                mt={4}
                backgroundColor="#009e93"
                onClick={() => {
                    auth0.loginWithRedirect();
                }}
            >
                Login
            </Button>
        </Flex>
    );
};

export default App;
