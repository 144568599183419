import {
    BooleanInput,
    Edit,
    EditProps,
    NumberInput,
    required,
    SimpleForm,
    TextInput
} from 'react-admin';

export const PromoCodeEdit = (props: EditProps) => (
    <Edit {...props}>
        <SimpleForm>
            <TextInput source="code" validate={[required()]} />
            <NumberInput source="discounted_cents" validate={[required()]} />
            <BooleanInput source="active" validate={[required()]} />
        </SimpleForm>
    </Edit>
);
