import { useMediaQuery } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import * as React from 'react';
import {
    BooleanField,
    Datagrid,
    DateField,
    List,
    ListProps,
    NumberField,
    SimpleList,
    TextField
} from 'react-admin';

export const PlansList = (props: ListProps) => {
    const theme = useTheme();
    const isSmall = useMediaQuery(theme.breakpoints.down('sm'));
    return (
        <List {...props}>
            {isSmall ? (
                <SimpleList
                    linkType="show"
                    primaryText={(record) => record.name}
                    secondaryText={(record) => new Date(record.date_created).toLocaleDateString()}
                />
            ) : (
                <Datagrid rowClick="show">
                    <TextField source="name" />
                    <NumberField source="price" options={{ style: 'currency', currency: 'USD' }} />
                    <BooleanField source="is_coming_soon" />
                    <DateField source="date_created" />
                    <DateField source="date_updated" />
                </Datagrid>
            )}
        </List>
    );
};
