import React from 'react';
import { ReferenceField, Show, ShowProps, SimpleShowLayout, TextField, UrlField } from 'react-admin';
import LatTitle from '../common/LatTitle';
import ApproveButton from './ApproveButton';


export const InvitationShow = (props: ShowProps) => (
  <Show title={<LatTitle source="name" />} {...props}>
    <SimpleShowLayout>
      <TextField source="name" />
      <TextField source="email" />
      <TextField source="zip_code" />
      <UrlField source="linkedin_url" />
      <ReferenceField label="Market" source="market_id" reference="market">
          <TextField source="name" />
      </ReferenceField>
      <TextField source="plan_preference" />
      <TextField source="charity" />
      <TextField source="about" />
      <TextField source="status" />
      <ApproveButton />
    </SimpleShowLayout>
  </Show>
);
