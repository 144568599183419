import React from 'react';
import {
    AutocompleteInput,
    BooleanInput,
    Edit,
    EditProps,
    ReferenceInput,
    SelectInput,
    SimpleForm,
    TextField,
    useRecordContext
} from 'react-admin';

enum LatitudeTransactionType {
    TRANSACTION = 'transaction',
    PREFERRED_TRANSACTION = 'preferred_transaction',
    LOCAL_TRANSACTION = 'local_transaction'
}

const BlacklistField = () => {
    const record = useRecordContext();
    // If there's no merchant name, nothing to blacklist
    if (!record.merchant_name) return null;
    return (
        <>
            <BooleanInput
                label="Blacklist"
                source="blacklist"
                helperText={`Add '${record.merchant_name}' to the blacklist`}
            />
        </>
    );
};

export const TransactionsEdit = (props: EditProps) => {
    return (
        <Edit {...props}>
            <SimpleForm>
                <TextField source="rize_uid" />
                <ReferenceInput label="Vendor" source="vendor_id" reference="vendor">
                    <AutocompleteInput
                        optionText="name"
                        resettable
                        helperText="Set the transaction vendor, will override the MX data"
                    />
                </ReferenceInput>
                <SelectInput
                    source="transaction_type"
                    choices={Object.values(LatitudeTransactionType).map((t) => ({
                        id: t,
                        name: t
                    }))}
                    helperText="Change the transaction type"
                />
                <BooleanInput
                    label="Reprocess Similar"
                    source="reprocess_similar"
                    helperText="If checked transactions from the same merchant (based on name) will also have their transaction type and points updated"
                />
                <BlacklistField />
            </SimpleForm>
        </Edit>
    );
};
