import { useMediaQuery } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import React from 'react';
import {
    BooleanField,
    Datagrid,
    DateField,
    Filter,
    ImageField,
    List,
    ListProps,
    ReferenceField,
    SimpleList,
    TextField,
    TextInput
} from 'react-admin';

const VendorFilter = (props: any): JSX.Element => (
    <Filter {...props}>
        <TextInput label="Search" source="q" alwaysOn />
    </Filter>
);

export const VendorList = (props: ListProps) => {
    const theme = useTheme();
    const isSmall = useMediaQuery(theme.breakpoints.down('sm'));
    return (
        <List {...props} filters={<VendorFilter />}>
            {isSmall ? (
                <SimpleList
                    linkType="show"
                    primaryText={(record) => record.name}
                    secondaryText={(record) => record.merchant_id}
                    tertiaryText={(record) => new Date(record.date_created).toLocaleDateString()}
                />
            ) : (
                <Datagrid rowClick="show">
                    <TextField source="name" />
                    <ImageField source="image" title="Merchant Image" />
                    <ReferenceField label="Market" source="market_id" reference="market">
                        <TextField source="name" />
                    </ReferenceField>
                    <BooleanField source="cashback_enabled" />
                    <DateField source="date_created" />
                    <DateField source="date_updated" />
                </Datagrid>
            )}
        </List>
    );
};
