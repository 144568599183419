import { useAuth0 } from '@auth0/auth0-react';
import { Button, useNotify } from 'react-admin';
import { syncTransactions } from '../../utils/api';

const SyncButton = ({ offset = 0 }) => {
    const auth0 = useAuth0();

    const notify = useNotify();
    const onSyncTransactions = async () => {
        const token = await auth0.getAccessTokenSilently();
        await syncTransactions(token, Number(offset) || 0);
        notify('Transaction sync started. Please check back in a few minutes');
    };

    return <Button label="Sync Transactions" onClick={onSyncTransactions} />;
};

export default SyncButton;
