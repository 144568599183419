import {
    BooleanInput,
    Create,
    CreateProps,
    NumberInput,
    required,
    SimpleForm,
    TextInput
} from 'react-admin';

export const PromoCodeCreate = (props: CreateProps) => (
    <Create {...props}>
        <SimpleForm>
            <TextInput source="code" validate={[required()]} />
            <NumberInput source="discounted_cents" validate={[required()]} />
            <BooleanInput source="active" validate={[required()]} />
        </SimpleForm>
    </Create>
);
