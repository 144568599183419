import React from 'react';
import { BooleanInput, Edit, EditProps, SimpleForm, TextField } from 'react-admin';

export const CategoryCodesEdit = (props: EditProps) => {
    return (
        <Edit {...props}>
            <SimpleForm>
                <TextField source="category_id" />
                <TextField source="description" />
                <BooleanInput source="local" />
            </SimpleForm>
        </Edit>
    );
};
