import { useMediaQuery } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import {
    BooleanField,
    Datagrid,
    Filter,
    List,
    ListProps,
    SimpleList,
    TextField,
    TextInput
} from 'react-admin';

const CategoryCodeFilter = (props: any) => (
    <Filter {...props}>
        <TextInput label="Search" source="q" alwaysOn />
    </Filter>
);

export const CategoryCodesList = (props: ListProps) => {
    const theme = useTheme();
    const isSmall = useMediaQuery(theme.breakpoints.down('sm'));
    return (
        <List {...props} filters={<CategoryCodeFilter />}>
            {isSmall ? (
                <SimpleList
                    linkType="show"
                    primaryText={(record) => record.category_id}
                    secondaryText={(record) => record.description}
                    tertiaryText={(record) => (record.local ? 'Local' : '')}
                />
            ) : (
                <Datagrid rowClick="show">
                    <TextField source="category_id" />
                    <TextField source="description" />
                    <BooleanField source="local" />
                </Datagrid>
            )}
        </List>
    );
};
