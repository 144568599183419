import { Identifier } from 'react-admin';
import dataConfig from './dataConfig';

export const approveRequest = async (token: string, requestId: Identifier) => {
    const headers = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
    };
    return await fetch(`${dataConfig.apiBaseUrl}/invitation/${requestId}/approve`, {
        method: 'POST',
        headers
    });
};

export const uploadImageRequest = async (token: string, image: File, folder?: string) => {
    const headers = {
        Authorization: `Bearer ${token}`
    };
    // Build formData object.
    let body = new FormData();
    body.append('image', image);
    if (folder) {
        body.append('folder', folder);
    }

    return await fetch(`${dataConfig.apiBaseUrl}/upload/image`, {
        method: 'POST',
        headers,
        body
    });
};

export const syncTransactions = async (token: string, offset = 0) => {
    const headers = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
    };
    return await fetch(`${dataConfig.apiBaseUrl}/transaction/sync?offset=${offset}`, {
        method: 'POST',
        headers
    });
};
