import {
    BooleanField,
    DateField,
    NumberField,
    Show,
    ShowProps,
    SimpleShowLayout,
    TextField
} from 'react-admin';
import LatTitle from '../common/LatTitle';

export const PromoCodeShow = (props: ShowProps) => (
    <Show title={<LatTitle source="code" />} {...props}>
        <SimpleShowLayout>
            <TextField source="code" />
            <NumberField source="discounted_cents" />
            <BooleanField source="active" />
            <DateField source="date_created" />
            <DateField source="date_updated" />
        </SimpleShowLayout>
    </Show>
);
